<template>
    <div class="uc-page">
        <div class="uc-first-card">
            <div class="uc-header flex-center u-m-b20">
                <div class="uc-grid flex-center">
                    <div class="uc-avatar">
                        <img v-if="user.headphoto" :src="user.headphoto">
                        <img v-else src="../../assets/pictures/default.png">
                    </div>
                    <div class="flex_bd">
                        <div class="uc-name u-m-b5" v-if="user && user.username" >
                            {{user.username}}
                        </div>
                        <div class="uc-invte" >
                            <span class="text">{{$t('邀请码')}}</span>
                            <span class="code">{{user.share_code}}</span>
                            <span class="iconfont icon-fuzhitubiao u-m-l5 btn-copy" data-clipboard-action="copy"
                                  :data-clipboard-text="user.share_code" @click="copy"></span>
                        </div>
                    </div>
                </div>
                <router-link to="/lang" class="btn btn-white m-l-auto" v-if="select_lang > 0">
                    <span class="iconfont icon-yuyan"></span>
                    <span>{{$t('选择语言')}}</span>
                </router-link>
            </div>
            <div class="uc-info-card">
                <div class="uc-info-top u-m-b20 flex-center">
                    <div class="bal-cell " v-if="user && user.username">
                        <div class="flex_bd">
                            <div class="bal-text">{{$t('我的钱包余额')}}</div>
                            <div class="bal-value">
                                {{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 12px">{{$t('USDT')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bal-cell m-l-auto" v-if="user && user.username">
                        <div class="flex_bd">
                            <div class="bal-text">{{$t('冻结本金')}}</div>
                            <div class="bal-value">
                                {{parseFloat(user.freeze).toFixed(2)}} <span style="font-size: 12px">{{$t('USDT')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uc-data-row u-m-b20" v-if="user.stat" >
                    <div class="uc-col">
                        <div class="uc-label" >{{$t('个人收益累计')}}</div>
                        <div class="uc-val">{{parseFloat(user.stat.today_incomes).toFixed(6)}}</div>
                    </div>
                    <div class="uc-col">
                        <div class="uc-label" >{{$t('今日代理收益')}}</div>
                        <div class="uc-val">{{parseFloat(user.stat.today_agent_incomes).toFixed(6)}}</div>
                    </div>
                    <div class="uc-col">
                        <div class="uc-label" >{{$t('代理累计收益')}}</div>
                        <div class="uc-val">{{parseFloat(user.stat.total_agent_incomes).toFixed(6)}}</div>
                    </div>
                    <div class="uc-col">
                        <div class="uc-label" >{{$t('机器人返佣')}}</div>
                        <div class="uc-val">{{parseFloat(user.stat.total_team_incomes).toFixed(6)}}</div>
                    </div>
                </div>
                <div class="robot-bar" @click="url('/robot')">
                    <img src="../../assets/pictures/avatar-default.png" class="bal-ico" >
                    <div class="flex_bd">{{$t('查看我的机器人仓库')}}</div>
                    <van-icon name="arrow" color="#3270e0"/>
                </div>
            </div>
        </div>
        <div class="uc-menu-cells">
            <div class="menu-panel">
                <ul class="menu-links">
                    <li>
                        <router-link to="/trade-record" class="menu-link-box">
                            <img src="../../assets/pictures/menu_tab_1.png" alt="">
                            <div class="text">{{$t('交易记录')}}</div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/income" class="menu-link-box">
                            <img src="../../assets/pictures/menu_tab_2.png" alt="">
                            <div class="text">{{$t('收入记录')}}</div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cost" class="menu-link-box">
                            <img src="../../assets/pictures/menu_tab_3.png" alt="">
                            <div class="text">{{$t('消费记录')}}</div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/withdraw-record" class="menu-link-box">
                            <img src="../../assets/pictures/menu_tab_4.png" alt="">
                            <div class="text">{{$t('取款记录')}}</div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/deposit-record" class="menu-link-box">
                            <img src="../../assets/pictures/menu_tab_5.png" alt="">
                            <div class="text">{{$t('存款记录')}}</div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bs-menu-panel">
            <div class="bs-panel_body">
                <div class="uc-card" @click="url('/bank')">
                    <img src="../../assets/pictures/uc_menu_1.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('我的银行卡')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div>
                <div class="uc-card" @click="url('/team')">
                    <img src="../../assets/pictures/uc_menu_2.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('我的团队')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div>
                <div class="uc-card" @click="url('/bonus')">
                    <img src="../../assets/pictures/uc_menu_3.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('代理返佣')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div>
                <div class="uc-card" @click="openService">
                    <img src="../../assets/pictures/uc_menu_4.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('网上客服')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div>
                <div class="uc-card" @click="url('/pay-set')" v-if="withdraw_info.pay_flag != 1">
                    <img src="../../assets/pictures/uc_menu_5.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('设置交易密码')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div>
                <!-- <div class="uc-card" @click="url('/login-pwd')">
                    <img src="../../assets/pictures/uc_menu_5.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('更改登录密码')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </div> -->
                <!-- <a class="uc-card" :href="download_url">
                    <img src="../../assets/pictures/uc_menu_6.png" alt="" class="uc-ico">
                    <div class="flex_bd">{{$t('下载APP')}}</div>
                    <van-icon name="arrow" color="#8a92b2" />
                </a> -->
            </div>
        </div>
        <div style="padding: 20px;">
            <div class="btn btn-out" @click="handleClickExit">{{$t('退出登录')}}</div>
        </div>
        <Foot :active="3"/>
        <van-popup v-model="setting_show" position="bottom" class="safe-pop" closeable close-icon="close">
            <div class="uc-grid flex-center" style="padding: 40px 15px 15px 15px;margin-bottom: 0;align-items: center">
                <div class="uc-avatar uc-avatar-setting" style="margin-right: 10px">
                    <img v-if="user.headphoto" :src="user.headphoto">
                    <img v-else src="../../assets/pictures/default.png" style="width: 40px;height: 40px;border-radius: 50%">
                </div>
                <div class="flex-bd">
                    <div class="uc-name" v-if="user && user.username" style="font-size:16px">
                        <div style="font-size:18px">{{user.username}}</div>
                        <div style="font-size: 12px">{{user.phone}}</div>
                    </div>
                </div>
                <div class="sale-auth">
                    <div class="btn s-btn btn-black" v-if="user.is_code_set == 1 || user.is_pay_set == 1 || user.is_email_set == 1">{{$t('保护中')}}</div>
                    <div class="btn s-btn s-btn-danger" v-else>{{$t('存在风险')}}</div>
                </div>
            </div>
            <div class="uc-panel-setting ">
                <div class="uc-card-setting" @click="url('/bank')">
                    <div class="iconfont"><img src="../../assets/pictures/uc_menu_1.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('我的银行卡')}}</div>
                    <div class="text-gray safe-set">
                        <van-icon name="arrow" class="safe-set2-icon" color="#ffffff" />
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/email-set')">
                    <div class="iconfont"><img src="../../assets/pictures/youxiang.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置Email')}}</div>
                    <div class=" safe-set" v-if="user.is_email_set == 0">
                        <div class="sefe-set-btn text-red">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else>
                        <div class="sefe-set-btn text-gray" style="background-color: #cccccc">{{$t('已设置')}}</div>
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/code-set')">
                    <div class="iconfont"><img src="../../assets/pictures/uc_menu_5.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置谷歌码')}}</div>
                    <div class=" safe-set" v-if="user.is_code_set == 0">
                        <div class="sefe-set-btn text-red">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else>
                        <div class="sefe-set-btn text-red" style="background-color: #cccccc">{{$t('已设置')}}</div>
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/pay-set')">
                    <div class="iconfont"><img src="../../assets/pictures/uc_menu_5.png" width="20" ></div>
                    <div class="flex_bd theme_5">{{$t('设置交易密码')}}</div>
                    <div class=" safe-set" v-if="user.is_pay_set == 0">
                        <div class="sefe-set-btn text-red">{{$t('去设置')}}</div>
                    </div>
                    <div class=" safe-set" v-else>
                        <div class="sefe-set-btn text-gray" style="background-color: #cccccc">{{$t('已设置')}}</div>
                    </div>
                </div>
                <div class="uc-card-setting" @click="url('/login-pwd')">
                    <div class="iconfont"><img src="../../assets/pictures/uc_menu_5.png" width="20"></div>
                    <div class="flex_bd theme_5">{{$t('更改登录密码')}}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";
    import Clipboard from "clipboard";

    export default {
        components: {
            Foot
        },
        data() {
            return {
                user: {},
                setting_show: false,
                download_url: '#',
                select_lang: false,
                withdraw_info: {},
            }
        },
        methods: {
            getWithdrawInfo() {
                this.$axios
                    .get(this.$api_url.withdraw_info)
                    .then((res) => {
                        this.withdraw_info = res.data;
                    })
            },
            openService(){
                window._vue.openService();
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            copy() {
                let clipboard = new Clipboard(".btn-copy");
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            getData() {
                // setTimeout(()=>{
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                    });
                // }, 20002);
            },
            handleClickExit() {
                this.$helper.forgetToken();
                location.href = "/#/welcome";
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
        },
        mounted() {
            this.download_url = localStorage.getItem('app.download_url') || '#';
            let download_url_android = localStorage.getItem('app.download_url_android') || '#';
            let download_url_ios = localStorage.getItem('app.download_url_ios') || '#';
            let deviceAgent = navigator.userAgent;
            let ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
            if(ios){
                this.download_url = download_url_ios;
            }else{
                this.download_url = download_url_android;
            }

            // let aqzx = localStorage.getItem("aqzx") || '';
            // if (aqzx > 0) {
            //     this.setting_show = true;
            // }
            // localStorage.removeItem("aqzx");

            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.getData();
            this.getWithdrawInfo();
        },
    }
</script>
<style scoped>
    .uc-panel-setting{
        padding: 20px;
    }
    .uc-card-setting{
        display: flex;
        font-size: 14px;
        align-items: center;
        padding: 15px;
        background-color: #1a2331;
        border-radius: 10px;
        margin-bottom: 12px;
    }
    .uc-card-setting .iconfont{
        margin-right: 10px;
    }
    .sale-auth{
        margin-left: auto
    }
    .s-btn-danger{
        background-color: #ed531d;
    }










</style>


